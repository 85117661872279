import Loader from 'react-dots-loader'
import 'react-dots-loader/index.css'

import './Loading.scss';

const Loading = (props = {visible: false}) => {

  const {visible} = props

  return (
    <div className={'loading ' + (visible ? 'visible' : '')}>
      <Loader color="#E30613"/>
    </div>
  );
}

export default Loading;
