import './Button.scss';

const Button = ({
  caption = '',
  onClick = null,
  type = 'primary-1',
  shape = 'round',
  style = {},
  children,
}) => {
  // const { caption, onClick, type, style } = props;

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={`${type} ${shape} buttonContainer`}
      onClick={handleButtonClick}
      style={style}
    >
      {children}
      {caption.toUpperCase()}
    </button>
  );
};

export default Button;
