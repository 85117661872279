import React, { useRef, useEffect } from 'react';

import './TextInput.scss';

const TextInput = (props) => {
  const {caption, type, value, onChange, required} = props;
  const inputEl = useRef(null);
  const labelEl = useRef(null);

  useEffect(() => {
    inputEl.current.value = value;
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (e) => {

    let value = e.target.value;
    onChange(value);
  }

  const convertToSlug = (Text) => {
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
  }

  const handleInputFocus = () => {
    labelEl.current.className = "small";
  }

  const handleInputBlur = (e) => {
    let value = e.target.value;
    
    if(value === ''){
      labelEl.current.className = 'big';
    }
  }

  const getType = () => {
    let allowedTypes = ['text', 'date', 'email', 'number', 'password'];
    if(allowedTypes.indexOf(type)){
      return type;
    }
    else{
      console.error('Type not supported: '+type);
      return 'text';
    }
  }

  return(
    <div className="inputContainer">
      <input id={convertToSlug(caption)} ref={inputEl} onFocus={handleInputFocus} onBlur={handleInputBlur} type={getType()} onChange={handleInputChange} required={required}/>
      <label htmlFor={convertToSlug(caption)} ref={labelEl} >{caption}</label>
    </div>
  )
}

export default TextInput;