import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Utils, TextInput, Button, Loading } from '../../Components/Components';

import '../Login/Login.scss';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const form = useRef();

  const handleChangeButton = async () => {
    setLoading(true);

    const email = localStorage.getItem('email');
    localStorage.removeItem('email');

    if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
      Utils.Alert({
        title: 'Warning',
        text: 'All fields are required!',
      });
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      Utils.Alert({
        title: 'Warning',
        text: "New password and confirmation don't match.",
      });
      setLoading(false);
      return;
    }

    const validation = await Utils.ValidateUser(email, oldPassword);

    if (validation.error) {
      Utils.Alert({
        title: 'Warning',
        text: 'The old password you entered is invalid',
      });
      setLoading(false);
      return;
    }

    const newPass = await Utils.GenerateHash(newPassword);

    const newPassData = {
      email: email,
      hash: newPass.hash,
    };


    // const result = await Utils.oic.post(
    //   '${process.env.REACT_APP_URL}/portal/update_hash',
    //   newPassData,
    // );

    let postData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: validation.token,
      },
      body: JSON.stringify(newPassData),
    };

    const result = await fetch(
      `${process.env.REACT_APP_URL}/portal/update_hash`,
      postData,
    );

    // await Utils.oic.post(
    //   '${process.env.REACT_APP_URL}/portal/update_hash',
    //   newPassData,
    // );

    if (result.status === 200) {
      Utils.Alert({
        title: 'Success',
        text: 'Password successfuly updated, please sign in again.',
        callback: () => {
          localStorage.removeItem('logedInUser');
          window.location.href = '/';
        },
      });
    } else {
      Utils.Alert({
        title: `Error: ${result.status}`,
        text: "Password couldn't be updated, please try again later",
      });
      setLoading(false);
    }
  };

  return (
    <div className="content loginPage">
      <Loading visible={loading} />
      <div className="card">
        <img className="wave-yellow" src="/images/waves.png" alt="" />
        <div className="welcome">
          <img src="/images/logos/Logo-flag.png" alt="" />
          <p className="text-shadow">
            Over four decades of history, Friboi has become the largest Beef
            packer and major Beef exporter in Brazil. Recognized as a leading
            global brand, with presence in over 70 countries we have the unique
            responsibility to take care of our beef which can be translated into
            quality, sustainability, food safety, health, nutrition and well
            being of millions of families in the world. This makes us very proud
            of doing our best every day to be a trustworthy beef brand. In order
            to feed the world with the best beef there is to offer we count on
            the most qualified team followed by the best facilities in Brazil.
          </p>
          <div className="logos">
            <div className="logoContainer">
              <img
                className="logo-1953"
                src="/images/logos/1953@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-black"
                src="/images/logos/Black-02.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-farms"
                src="/images/logos/Farms@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-grain"
                src="/images/logos/Grain Valley@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img src="/images/logos/Steakhouse@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div className="login">
          <div className="logo">
            <div className="logo-image">
              <img src="/images/logos/FRIBOI_White.png" alt="" />
            </div>
            <label htmlFor="" className="resetPasswordLabel">
              Change
              <br /> Password
            </label>
          </div>
          <div className="error">{error}</div>
          <form
            name="loginForm"
            className="form"
            ref={form}
            onKeyDown={(e) => {
              if (e.which === 13) {
                // 
              }
            }}
          >
            <div className="email field">
              <TextInput type="password" value={oldPassword} caption="Old Password" onChange={setOldPassword} />
              <TextInput type="password" value={newPassword} caption="New Password" onChange={setNewPassword} />
              <TextInput type="password" value={confirmPassword} caption="Confirm Password" onChange={setConfirmPassword} />
            </div>
            <div className="loginButton">
              <Button
                type="red"
                caption="Confirm"
                onClick={handleChangeButton}
              />
            </div>
            <div className="back">
              <Link to="/">Back to login page</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
