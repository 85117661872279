import React from 'react';

import './Subtitle.scss';

const Subtitle = ({ text = 'Subtitle', tip }) => {
  return (
    <div className="subtitleContainer noselect" id="subtitleContainer">
      <h1>{text}</h1>
      {tip &&
        <div className="tip">
          <div className="tipContainer">
            {tip}
          </div>
          <h2>?</h2>
        </div>

      }
    </div>
  );
}

export default Subtitle;