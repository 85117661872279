import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Utils, TextInput, Button, Loading } from '../../Components/Components';

import '../Login/Login.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleResetButton = async () => {
    if (email.length === 0) {
      setError('Please provide the Email');
    } else {
      setLoading(true);
      setError('');
      let response = await Utils.ForgotPassword(email);
      if (response) {
        Utils.Alert({
          title: 'Password Reseted',
          text: 'We generated a password and sent it to the email you provided.',
          callback: () => {
            window.location.href = '/';
          },
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className="content loginPage">
      <Loading visible={loading} />
      <div className="card">
        <img className="wave-yellow" src="/images/waves.png" alt="" />
        <div className="welcome">
          <img src="/images/logos/Logo-flag.png" alt="" />
          <p className="text-shadow">
            Over four decades of history, Friboi has become the largest Beef
            packer and major Beef exporter in Brazil. Recognized as a leading
            global brand, with presence in over 70 countries we have the unique
            responsibility to take care of our beef which can be translated into
            quality, sustainability, food safety, health, nutrition and well
            being of millions of families in the world. This makes us very proud
            of doing our best every day to be a trustworthy beef brand. In order
            to feed the world with the best beef there is to offer we count on
            the most qualified team followed by the best facilities in Brazil.
          </p>
          <div className="logos">
            <div className="logoContainer">
              <img
                className="logo-1953"
                src="/images/logos/1953@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-black"
                src="/images/logos/Black-02.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-farms"
                src="/images/logos/Farms@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-grain"
                src="/images/logos/Grain Valley@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img src="/images/logos/Steakhouse@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div className="login">
          <div className="logo">
            <div className="logo-image">
              <img src="/images/logos/FRIBOI_White.png" alt="" />
            </div>
            <label htmlFor="" className="resetPasswordLabel">
              Reset
              <br /> Password
            </label>
          </div>
          <div className="error">{error}</div>
          <form
            name="loginForm"
            className="form"
            ref={form}
            onKeyDown={(e) => {
              if (e.which === 13) {
                handleResetButton();
              }
            }}
          >
            <div className="email field">
              <TextInput value={email} caption="Email" onChange={setEmail} />
            </div>
            <div className="loginButton">
              <Button
                type="red"
                caption="Reset Password"
                onClick={handleResetButton}
              />
            </div>
            <div className="back">
              <Link to="/">Back to login page</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
