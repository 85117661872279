import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Base64 as base64 } from 'js-base64';
import Moment from 'react-moment';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

const Utils = {
  get: async (url) => {
    let token = '';
    const ldata = await localStorage.getItem('logedInUser');
    if (ldata) {
      token = await JSON.parse(ldata).token;
    }
    let getData = {
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const res = await fetch(url, getData);
    if (res.status === 401) {
      localStorage.removeItem('logedInUser');
      window.location.reload();
    }
    const response = await res.json();
    return { status: res.status, data: response };
  },

  post: async (url, data = {}) => {
    let token = '';
    const ldata = await localStorage.getItem('logedInUser');
    const json = JSON.parse(ldata);
    if (ldata) {
      token = json.token;
    }
    let postData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify(data),
    };
    const res = await fetch(url, postData);
    if (res.status === 401) {
      if (token !== '') {
        //localStorage.removeItem('logedInUser');
        //window.location.reload();
      }
    }
    const response = await res.json();
    return { status: res.status, data: response };
  },

  getToken: () => { },

  Alert: ({ title = '', text = '', callback }) => {
    confirmAlert({
      overlayClassName: 'alert',
      customUI: ({ onClose }) => {
        return (
          <div className="customAlert">
            <h1>{title}</h1>
            <p>{text}</p>
            <div className="buttons">
              <button className="primary" onClick={callback || onClose}>
                OK
              </button>
            </div>
          </div>
        );
      },
    });
  },

  Confirm: (props) => {
    confirmAlert({
      overlayClassName: 'confirm',
      customUI: ({ onClose }) => {
        return (
          <div className="customAlert">
            <h1>{props.title}</h1>
            <p>{props.text}</p>
            <div className="buttons">
              <button className="primary" onClick={props.onYes || onClose}>
                Yes
              </button>
              <button className="secondary" onClick={props.onNo || onClose}>
                No
              </button>
            </div>
          </div>
        );
      },
    });
  },

  ValidateUser: async (username, password) => {
    let data = { email: username, password: password };
    let response = await Utils.post(
      `${process.env.REACT_APP_URL}/portal/validate_user`,
      { data: base64_encode(JSON.stringify(data)) },
    );

    let status = {
      error: false,
      token: '',
      changePassword: response.data.isPassTemporary,
      lastPassUpdate: new Date(response.data.lastPassUpdate),
    };

    if (response.status === 200) {
      if (status.changePassword)
        localStorage.setItem('email', username);
      
      status.token = response.data.token;
      localStorage.setItem(
        'logedInUser',
        JSON.stringify({ email: username, token: response.data.token }),
      );
      

      // status.name = response.data.name;
      // status.account = response.data.account;
      // status.code = response.data.code;
    } else {
      status.error = response.status;
    }
    return response, status;
  },

  FirstLogin: async (email) => {
    let errorMesssages = {
      not_found:
        'Email not found, please make sure you typed the right email, if the problem persists, please contact us',
      not_first_login:
        "This email already has a valid password. If you don't remember the password, please try the 'Forgot Password' session",
    };

    let response = await Utils.post(
      `${process.env.REACT_APP_URL}/portal/first_login`,
      { email: email },
    );
    if (response.status === 401) {
      Utils.Alert({
        title: 'Error',
        text: errorMesssages[response.data.error],
      });
      response.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      return false;
    }
    if (response.status === 200) {
      return response.data;
    }
  },

  getAccountList: async () => {
    const email = await JSON.parse(localStorage.getItem('logedInUser'));

    let { data } = await Utils.post(
      `${process.env.REACT_APP_URL}/portal/clientes`,
      email
    );

    const filterData = data.map((conta) => {
      let nameConta = '';
      let erpConta = '';

      if (conta.ContaNomeLogistico) {
        nameConta = conta.ContaNomeLogistico;
        erpConta = conta.ContaERPLogistico;
      }
      else {
        nameConta = conta.ContaNomeBuyer;
        erpConta = conta.ContaERPBuyer;
      }

      let Mensagem = conta.Mensagem;
      let regionalContato = conta.RegionalContato;
      let areaContato = conta.AreaContato;

      return { nameConta, erpConta, Mensagem, regionalContato, areaContato };
    });

    filterData.sort((a, b) => {
        const contaA = a.nameConta?.toUpperCase();
        const contaB = b.nameConta?.toUpperCase();
    
        if (contaA < contaB) {
            return -1;
        }
        if (contaA > contaB) {
            return 1;
        }
        return 0;
    });   

    let jsonObject = filterData.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    return uniqueArray;
  },

  ForgotPassword: async (email) => {
    let errorMesssages = {
      not_found:
        'Email not found, please make sure you typed the right email, if the problem persists, please contact us',
      not_first_login:
        "This email already has a valid password. If you don't rememberr the password, please try the 'Forgot Password' session",
    };

    let response = await Utils.post(
      `${process.env.REACT_APP_URL}/portal/forgot_password`,
      { email: email },
    );
    if (response.status === 401) {
      Utils.Alert({
        title: 'Error',
        text: errorMesssages[response.data.error],
      });
      return false;
    }
    if (response.status === 200) {
      return response.data;
    }
  },

  GenerateHash: async (pass) => {
    const passEncoded = base64_encode(pass);
    const response = await Utils.post(
      `${process.env.REACT_APP_URL}/portal/generate_hash`,
      { data: passEncoded },
    );


    if (response.status === 200) {
      return response.data;
    } else {
      Utils.Alert({
        title: `Error: ${response.status}`,
        text: `We were not able to hash your password, please try again later.`,
      });
      return;
    }
  },

  getCache: async (key) => {
    const { email } = await JSON.parse(localStorage.getItem('logedInUser'));
    let { data } = await Utils.get(
      `${process.env.REACT_APP_URL}/portal/cache/${email}`,
    );

    if (data.length > 0) {
      // console.log("data", data);
      let cache = data[0].data ? JSON.parse(data[0].data) : false;
      if (cache && cache[key]) {
        return cache[key];
      }
    }

    Utils.setCache(key, { columns: [], columnFilter: [], orderBy: {} });
    return { columns: [], columnFilter: [], orderBy: {} };
  },

  setCache: async (key, value) => {
    const { email } = await JSON.parse(localStorage.getItem('logedInUser'));
    let { data } = await Utils.get(
      `${process.env.REACT_APP_URL}/portal/cache/${email}`,
    );
    let cache = {};
    if (data[0].data !== undefined) {
      cache = JSON.parse(data[0].data);
      cache[key] = value;
      await Utils.post(`${process.env.REACT_APP_URL}/portal/cache`, {
        email: email,
        data: JSON.stringify(cache),
      });
    } else {
      await Utils.post(`${process.env.REACT_APP_URL}/portal/cache`, {
        email: email,
        data: JSON.stringify({ [key]: value }),
      });
    }
  },

  getUserData: async () => {
    const { email } = await JSON.parse(localStorage.getItem('logedInUser'));
    let { data } = await Utils.get(
      `${process.env.REACT_APP_URL}/portal/cache/${email}`,
    );
    if (data.length > 0) {
      let cache = JSON.parse(data[0].user);
      return cache;
    }
  },

  // getClientsShippingData: async () => {

  //   const { email } = await JSON.parse(localStorage.getItem('logedInUser'));

  //   let { data } = await Utils.get(
  //     `${process.env.REACT_APP_URL}/portal/shipping-clientes/`, email
  //   );

  //   return data;
  // },

  getShippingData: async (account, user) => {
    let array = [];
    let hasMoreFlag = true;
    let offSetFlag = 0;

    while (hasMoreFlag) {
      let { data } = await Utils.get(
        `${process.env.REACT_APP_URL}/portal/shipping?userId=${account.erpConta}&offset=${offSetFlag}&regional=${account.regionalContato || ''}&area=${account.areaContato.replace("'", "''") || ''}&nameClient=${account.nameConta}&name=${user.name}&email=${user.email}`
      );

      if (data.hasMore === true) {
        offSetFlag = offSetFlag + 500;
      }

      if (data.Shipping?.length > 0) {

        data.Shipping.sort((a, b) => {
          const dataA = new Date(a.dLancamento);
          const dataB = new Date(b.dLancamento);

          if (dataA < dataB) {
            return 1;
          }
          if (dataA > dataB) {
            return -1;
          }

          const numeroA = parseInt(a.cPedidoCarga.split("-")[0]);
          const numeroB = parseInt(b.cPedidoCarga.split("-")[0]);

          if (numeroA < numeroB) {
            return -1;
          }
          if (numeroA > numeroB) {
            return 1;
          }

          const numeroA2 = parseInt(a.cPedidoCarga.split("-")[1]);
          const numeroB2 = parseInt(b.cPedidoCarga.split("-")[1]);

          if (numeroA2 < numeroB2) {
            return -1;
          }
          if (numeroA2 > numeroB2) {
            return 1;
          }

          return 0;
        });

        let response = data.Shipping.map((Shipping) => {
          let prepaymentStatus = '';

          if (Shipping.cExistePPA === '0') prepaymentStatus = 'NA'
          else if (Shipping.cExistePPA === '1' && (!Shipping.dLiberacaoPPA || Shipping.dLiberacaoPPA === '1900-01-01')) prepaymentStatus = 'Pending'
          else prepaymentStatus = 'Prepaid';

          return {
            IdShipping: Shipping.IdShipping,
            'Contract&Load': Shipping.cPedidoCarga,
            Contract: Shipping.nCdPedidoComercial,
            // Load: Shipping.iCarga,
            Status: Shipping.cStatus.trim(),
            'Emission Date': Shipping.dLancamento,
            Buyer: Shipping.nomeTerceiro,
            'Purchase Order': Shipping.cPedidoTerceiro === "X" ? "" : Shipping.cPedidoTerceiro,
            Client: Shipping.nomeTerceiroEntrega,
            Consignee: Shipping.nomeConsignatario,
            Currency: Shipping.nCdIndexador,
            'Purchase Order Load': Shipping.cCargaTerceiro === "X" ? "" : Shipping.cCargaTerceiro,
            'Agreed Date': Shipping.dDesejadaCliente,
            'Production Plant': Shipping.nCdEmpresa,
            'Loading Date': Shipping.dPCP,
            'Stuffing Date': Shipping.dFaturamento,
            Incoterm: Shipping.nModalidadeVenda,
            'Container Type': Shipping.nTpContainer,
            'Country of Destination': Shipping.nCdPaisDestinoFinal,
            'Payment Term': Shipping.cCondicaoPgto,
            'Prepayment Status': prepaymentStatus,
            'Prepayment Date': Shipping.dLiberacaoPPA,
            'Received Prepayment': Shipping.nValorTotalRecebimentoCambial,
            'Payment Received': Shipping.nValorTotalRecebimentoCambial,
            'Vehicle License Plate': Shipping.cPlacaVeiculo,
            'Booking Number': Shipping.cNrBooking,
            'Port of Loading': Shipping.nCdPortoOrigemBooking,
            'Port of Destination': Shipping.nCdPortoDestinoBooking,
            'Shipping Line': Shipping.nTerceiroAgenciaMaritima,
            Container: Shipping.cContainerBL,
            ETD: Shipping.dETDOrigem,
            'Departure date from the plant': Shipping.dSaidaEmpresa,
            'BL Date': Shipping.dBL,
            'BL Number': Shipping.cNrBL,
            // 'Invoice Buyer': Shipping.nCdTerceiroInvoiceBL,
            'BL Consignee': Shipping.nCdTerceiroConsignatarioBL,
            'Courier Number': Shipping.cNrCourier,
            Vessel: Shipping.cVessel,
            // 'Settlement Date': Shipping.SettlementDate_c,
            // PrePayment: Shipping.PrePayment_c,
            // 'Total Amount': Shipping.ValorCarga_c,
            // '% PPA Requested': Shipping.PorcentagemPPA_c,
            // 'Advanced Amount': Shipping.ValorPPA_c,
            // 'Missing Prepayment': Shipping.MissingPrepayment,
            // 'Financial Ref': Shipping.nCdTituloFinanceiro,
            // 'Expiration Date': Shipping.dVencimento,
            // 'Pending Balance Value': Shipping.SaldoIndexador_c,
            // 'Balance': Shipping.Balance,
            'FlagPendingBalance': Shipping.FlagPendingBalance,
            'FlagOverdue': Shipping.FlagOverdue,
            'FlagPending': Shipping.FlagPending,
            'FlagPrepaid': Shipping.FlagPrepaid,
            // 'Partial Balance Payment': Shipping.PartialBalancePayment,
            // '% Pending Balance': Shipping.PorcentagemPendingBalance,
            // '% Overdue': Shipping.PorcentagemOverdue,
            // 'Overdue': Shipping.Overdue,
            // 'Pending Balance': Shipping.PendingBalance,
            'Net Value': Shipping.ValorIndexador,
            'Currency': Shipping.Currency,
            // 'Regional': Shipping.Regional,
            'Old Order': Shipping.nPedidoSubstituto,
            'Product': Shipping.ComboProduct,
            'Agreed Week of Production': Shipping.AgreedWeekOfProduction
          };
        });

        array = [...array, ...response];
      }

      hasMoreFlag = data.hasMore;
    }
    return array;
  },

  getShippingDataWithDocuments: async (userId) => {
    let array = [];
    let hasMoreFlag = true;
    let offSetFlag = 0;

    while (hasMoreFlag) {
      let { data } = await Utils.get(
        `${process.env.REACT_APP_URL}/portal/shipping/${userId}/${offSetFlag}`,
      );

      if (data.hasMore === true) {
        offSetFlag = offSetFlag + 500;
      }

      if (data.Shipping.length > 0) {
        let response = data.Shipping.map((Shipping) => {
          let prepaymentStatus = '';

          if (Shipping.cExistePPA === '0') prepaymentStatus = 'NA'
          else if (Shipping.cExistePPA === '1' && (!Shipping.dLiberacaoPPA || Shipping.dLiberacaoPPA === '1900-01-01')) prepaymentStatus = 'Pending'
          else prepaymentStatus = 'Prepaid';

          return {
            IdShipping: Shipping.IdShipping,
            'Contract&Load': Shipping.cPedidoCarga,
            Contract: Shipping.nCdPedidoComercial,
            Load: Shipping.iCarga,
            Status: Shipping.cStatus.trim(),
            'Emission Date': Shipping.dLancamento,
            Buyer: Shipping.nomeTerceiro,
            'Purchase Order': Shipping.cPedidoTerceiro,
            Client: Shipping.nomeTerceiroEntrega,
            Consignee: Shipping.nomeConsignatario,
            Currency: Shipping.nCdIndexador,
            'Purchase Order Load': Shipping.cCargaTerceiro,
            // 'Agreed Date': Shipping.dDesejadaCliente,
            'Production Plant': Shipping.nCdEmpresa,
            'Loading Date': Shipping.dPCP,
            'Stuffing Date': Shipping.dFaturamento,
            Incoterm: Shipping.nModalidadeVenda,
            // 'Container Type': Shipping.nTpContainer,
            'Country of Destination': Shipping.nCdPaisDestinoFinal,
            'Payment Term': Shipping.cCondicaoPgto,
            'Prepayment Status': prepaymentStatus,
            'Prepayment Date': Shipping.dLiberacaoPPA,
            // 'Payment Received': Shipping.nValorTotalRecebimentoCambial,
            'Vehicle License Plate': Shipping.cPlacaVeiculo,
            'Booking Number': Shipping.cNrBooking,
            'Port of Loading': Shipping.nCdPortoOrigemBooking,
            'Port of Destination': Shipping.nCdPortoDestinoBooking,
            'Shipping Line': Shipping.nTerceiroAgenciaMaritima,
            Container: Shipping.cContainerBL,
            ETD: Shipping.dETDOrigem,
            'Departure date from the plant': Shipping.dSaidaEmpresa,
            'BL Date': Shipping.dBL,
            'BL Number': Shipping.cNrBL,
            // '': Shipping.cContainerBL,
            'Invoice Buyer': Shipping.nCdTerceiroInvoiceBL,
            'BL Consignee': Shipping.nCdTerceiroConsignatarioBL,
            'Courier Number': Shipping.cNrCourier,
            Vessel: Shipping.cVessel,
            'Settlement Date': Shipping.SettlementDate_c,
          };
        });

        array = [...array, ...response];
      }

      hasMoreFlag = data.hasMore;
    }

    array.forEach(async (element, index) => {
      // const { data } = await Utils.get(
      //   `${process.env.REACT_APP_URL}/portal/shipping-documents/${element.IdShipping}`,
      // );

      // console.log(data);
    });

    // let documents = await Utils.get(
    //   `${process.env.REACT_APP_URL}/portal/account/documents/${userId}`,
    // );

    // console.log('documents', documents);
    return array;
  },

  getProducts: async (shippingId) => {
    let { data } = await Utils.get(
      `${process.env.REACT_APP_URL}/portal/shipping-products/${shippingId}`,
    );

    if (data.message) {
      return [];
    }

    let response = data.ShippingItens.map((ShippingItens) => {
      return {
        Product: ShippingItens.nCdProduto,
        Description: ShippingItens.nomeProduto,
        'Number of Cartons': ShippingItens.nTotalVolume,
        'Net Weight': ShippingItens.nTotalPeso,
        'Gross Weight': ShippingItens.nPesoBruto,
        'Quantity (KGs)': ShippingItens.nQtde,
        'Unit of Measurement': ShippingItens.nCdUnidadeMedida,
        Price: ShippingItens.nPreco,
        // 'Estimated Net Weight': ShippingItens.nPesoLiquidoEstimado,
        // 'Estimated Gross Weight': ShippingItens.nPesoBrutoEstimado,
        // 'Health Certificate': ShippingItens.cCSICertificado,
        // 'Shipping Mark': ShippingItens.cContraMarca,
      };
    });
    return response;
  },

  getDocuments: async (shippingId) => {


    let { data } = await Utils.get(
      `${process.env.REACT_APP_URL}/portal/shipping-documents/${shippingId}`,
    );

    if (data.message) {
      return [{ FileName: '', Description: '', GUID: '', UploadDate: ''}];
    }

    let response = data.ShippingDocuments.map((Documents) => {
      // console.log('Documentos: ', Documents);
      return {
        Categoria: Documents.Categoria,
        FileName: Documents.FileName_c,
        Description: Documents.Description_c,
        GUID: Documents.GUID_c,
        UpdateDate_c: Documents.UpdateDate_c,
      };
    });

    return response;

  },

  getFinancial: async (PedidoCarga) => {
    let { data } = await Utils.get(
      `${process.env.REACT_APP_URL}/portal/shipping-financial/${PedidoCarga}`
    );

    return data;
  },

  paymentFinancial: async (ShippingId, fileName, conteudo,
    justificationVal, paymentVal, email, valorTotal, abaDePagamento, clientName) => {

    let data = {
      NomeArquivo: fileName, Conteudo: conteudo,
      Justification: justificationVal, PaymentValue: paymentVal, email: email,
      Total: valorTotal, currentPage: abaDePagamento, customerName: clientName
    };

    // console.log("data");
    // console.log(data);


    // let data = [];

    // if(ShippingId.length > 0){
    //   console.log("ShippingId.length");
    //   console.log(ShippingId.length);

    //   for(let i=0; i<ShippingId.length; i++){
    //     let arrayPost = { ShippingId: ShippingId[i], NomeArquivo: nome, Conteudo: conteudo , 
    //       Justification: justification, PaymentValue: paymentvalue, email: email,
    //       Total: Total, currentPage: abaDePagamento, customerName: nomeConta};
    //     data.push(arrayPost);    
    //   } 
    //   console.log(data);
    // }

    let { response } = await Utils.post(
      `${process.env.REACT_APP_URL}/portal/shipping-payment/${ShippingId}`,
      data
    );

    return response;

  },

  getDocumentBase64: async (GUID) => {

    let { data } = await Utils.get(
      `${process.env.REACT_APP_URL}/portal/shipping-document-download/${GUID}`,
    );

    return data;
  },

  getAccountDocuments: async (accountId) => {
    let { data } = await Utils.get(
      `${process.env.REACT_APP_URL}/portal/shipping/account/documents/${accountId}`,
    );
    let documents = [];
    let response = data.ShippingDocuments.map((Documents) => {
      documents = response.map((document) => {
        return {
          IdShipping: document.IdShipping,
          FileName: document.FileName_c,
          Description: document.Description_c,
          GUID: document.GUID_c,
          UploadDate: document.UploadDate_c,
        };
      });
    });
    return documents;
  },

  currencyCols: ['Payment Received'],

  dateCols: [
    'Loading Date',
    'Emission Date',
    'Agreed Date',
    'Loading Date',
    'Stuffing Date',
    'ETD',
    'Departure date from the plant',
    'BL Date',
    'Prepayment Date',
    'Settlement Date',
  ],

  outputData: (val, col) => {
    if (Utils.dateCols.indexOf(col) >= 0) {
      if (val == '9999-12-31' || val == '1900-01-01' || val == '') {
        return '';
      } else {
        return <Moment format="DD-MM-YYYY">{val}</Moment>;
      }
    }

    // if (Utils.currencyCols.indexOf(col) >= 0) {
    //   let currency = new Intl.NumberFormat('de-DE', {
    //     style: 'currency',
    //     currency: 'EUR',
    //   }).format(val);
    //   return currency.replace(/\s€/g, '');
    // }

    return val;
  },

  oic: {
    post: async (url, data = {}) => {
      let auth = `${process.env.REACT_APP_OIC_USER}:${process.env.REACT_APP_OIC_PASS}`;
      let postData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64.encode(auth)}`,
        },
        body: JSON.stringify(data),
      };
      const res = await fetch(url, postData);
      const response = await res.json();
      return { status: res.status, data: response };
    },
    get: async (url, data = {}) => {
      let query = Object.keys(data)
        .map((item) => {
          return `${item}=${data[item]}`;
        })
        .join('&');
      let auth = `${process.env.REACT_APP_OIC_USER}:${process.env.REACT_APP_OIC_PASS}`;
      let header = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64.encode(auth)}`,
        },
      };
      const res = await fetch(`${url}?${query}`, header);
      const response = await res.json();
      return { status: res.status, data: response };
    },
  },

  shipping: {
    status: {
      1: 'To be Produced',
      2: 'Under Production',
      3: 'Scheduled to be loaded',
      4: 'Loaded on the Plant',
      5: 'In Transit',
      6: 'Container at the Port',
      7: 'Shipped',
      8: 'Settled',
      9: 'Canceled',
    },
  }
};

export default Utils;
