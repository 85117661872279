import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { TextInput, Button, Loading, Utils } from '../../Components/Components';

import './Login.scss';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleLoginButton = async () => {
    if (username.length === 0) {
      setError('Please provide the Username');
    } else if (password.length === 0) {
      setError('Please provide the Password');
    } else {
      setLoading(true);
      setError('');
      let validation = await Utils.ValidateUser(username, password);

      const validDate = (lastUpdate) => {
        const currentDate = new Date();

        const timeDiff = Math.abs(currentDate.getTime() - lastUpdate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return diffDays;
      }

      if (validation.error) {
        let error = {};
        switch (validation.error) {
          case 401:
            error = {
              title: 'Login Failed',
              text: 'Invalid username/password, please check your credencials and try again',
            };
            break;
          case 500:
            error = {
              title: 'Internal server error',
              text: "We couldn't reach the server, please try again. \nIf the problem persists, please contact the system administrator",
            };
            break;
          default:
            error = {
              title: 'Error',
              text: `Request unavailable.\nError: ${validation.error}`,
            };
        }
        Utils.Alert(error);
      } else {
        if (validation.changePassword) {
          localStorage.removeItem('logedInUser');
          window.location.href = '/changepassword';
          return;
        }
        
        const diffDays = validDate(validation.lastPassUpdate);

        if (diffDays >= 60) {
          if (diffDays >= 75) {
            localStorage.removeItem('logedInUser');

            Utils.Alert({
              title: 'Warning',
              text: `Your password has expired. Please reset your password.`,
              callback: () => window.location.href = '/forgotpassword',
            });

            return;
          }

          Utils.Alert({
            title: 'Warning',
            text: `Your password wil expire in ${75 - diffDays} days. Please reset your password as soon as possible.`,
            callback: () => window.location.href = '/',
          });

          return;
        }
        
        // await localStorage.setItem(
        //   'logedInUser',
        //   JSON.stringify({ email: username, token: validation.token }),
        // );
        window.location.href = '/';
      }
    }

    setLoading(false);
  };

  return (
    <div className="content loginPage">
      <Loading visible={loading} />
      <div className="card">
        <img className="wave-yellow" src="/images/waves.png" alt="" />
        <div className="welcome">
          <img src="/images/logos/Logo-flag.png" alt="" />
          <p className="text-shadow">
            Over four decades of history, Friboi has become the largest Beef
            packer and major Beef exporter in Brazil. Recognized as a leading
            global brand, with presence in over 70 countries we have the unique
            responsibility to take care of our beef which can be translated into
            quality, sustainability, food safety, health, nutrition and well
            being of millions of families in the world. This makes us very proud
            of doing our best every day to be a trustworthy beef brand. In order
            to feed the world with the best beef there is to offer we count on
            the most qualified team followed by the best facilities in Brazil.
          </p>
          <div className="logos">
            <div className="logoContainer">
              <img
                className="logo-1953"
                src="/images/logos/1953@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-black"
                src="/images/logos/Black-02.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-farms"
                src="/images/logos/Farms@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img
                className="logo-grain"
                src="/images/logos/Grain Valley@2x.png"
                alt=""
              />
            </div>
            <div className="logoContainer">
              <img src="/images/logos/Steakhouse@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div className="login">
          <div className="logo">
            <div className="logo-image">
              <img src="/images/logos/FRIBOI_White.png" alt="" />
            </div>
            <label htmlFor="">
              Client
              <br />
              Portal
            </label>
          </div>
          <div className="error">{error}</div>
          <form name="loginForm" className="form" ref={form}>
            <div className="username field">
              <TextInput
                value={username}
                caption="Username"
                onChange={setUsername}
              />
            </div>
            <div className="password field">
              <TextInput
                value={password}
                type="password"
                caption="Password"
                onChange={setPassword}
              />
            </div>
            <div className="loginButton">
              <Button type="red" caption="Login" onClick={handleLoginButton} />
            </div>
            <div className="forgotpassword">
              <Link to="/forgotpassword">Forgot Password?</Link>
            </div>
            <div className="forgotpassword">
              <Link to="/firstlogin">First Login?</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
