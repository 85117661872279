import React, { useRef, useEffect, useContext } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import './Shipping.scss';

import Schedule from './Schedule/Schedule';
import Financial from './Financial/Financial';
import FinancialBalance from './Financial/FinancialBalance';
import { UserContext } from '../../Components/Contexts';

const Shipping = () => {
  
  const history = useHistory();
  const location = useLocation();
  const tabs = {
    schedule: useRef(),
    financial: useRef(),
    balance: useRef(),
  };

  useEffect(() => {
    Object.keys(tabs).forEach((item) => {
      tabs[item].current.classList.remove('selected');
    });
    const { pathname } = location;
    switch (pathname) {
      case '/shipping/financial':
        tabs.financial.current.classList.add('selected');
        break;
      case '/shipping/schedule-documents':
      tabs.schedule.current.classList.add('selected');
        break;
      case '/shipping/balance':
        tabs.balance.current.classList.add('selected');
        break;
      default:
        tabs.schedule.current.classList.add('selected');
    }
  }, [location]);

  const handleTabClick = (tab) => {
    Object.keys(tabs).forEach((item) => {
      tabs[item].current.classList.remove('selected');
    });

    switch (tab) {
      case 1:
        history.push('/shipping/schedule-documents');
        tabs.schedule.current.classList.add('selected');
        break;
      case 2:
        history.push('/shipping/financial');
        tabs.financial.current.classList.add('selected');
        break;
      case 3:
      history.push('/shipping/balance');
      tabs.balance.current.classList.add('selected');
      break;
      default:
    }
  };

  return (
    <div className="pageContent">
      <div className="componentContent">
        <ul className="tabs">
          <li
            ref={tabs.schedule}
            onClick={(e) => {
              handleTabClick(1);
            }}
          >
            Schedule / Documents
          </li>
          <li
            ref={tabs.financial}
            onClick={(e) => {
              handleTabClick(2);
            }}
          >
            Prepayment
          </li>
          <li
            ref={tabs.balance}
            onClick={(e) => {
              handleTabClick(3);
            }}
          >
            Pending Balance
          </li>
        </ul>
          <Switch>
            <Route exact path="/shipping/schedule-documents">
              <Schedule />
            </Route>
            <Route exact path="/shipping/financial">
              <Financial />
            </Route>
            <Route exact path="/shipping/balance">
              <FinancialBalance/>
            </Route>
          </Switch>
      </div>
    </div>
  );
};

export default Shipping;
