// dependencies
// npm  install xlsx file-saver react-export-excel react-csv node-sass@4.14.1 --save

import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Loader from 'react-dots-loader';
import 'react-dots-loader/index.css';

import { Utils } from '../../Components/Components';

import './Table.scss';

import ReactExport from 'react-export-excel';
import CsvDownloader from 'react-csv-downloader';

const Table = ({
  header = [],
  data = [],
  itemsPerPage = 10,
  startPage = 1,
  itemClick,
  idCol = '',
  fixedCols = [],
  hiddenCols = [],
  // setTableFilters = Function,
  setTotalReceived = Function,
  noOrderBy = false,
  noExport = false,
  noChangeColumns = false,
  noCookie = false,
  cache = '',
  orderedCols = false,
  valorTotalFinal = "",
  columnFilter,
  setColumnFilter,
}) => {
  if (localStorage.getItem("itemPorPagina")) {
    itemsPerPage = localStorage.getItem("itemPorPagina");
  } else {
    itemsPerPage = 10;
  }

  // Excel stuff
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const loaded = useRef(false);
  const tableContainer = useRef();
  const changeColumnMenu = useRef();
  const changeColumnButton = useRef();
  const orderByMenu = useRef();
  const orderByButton = useRef();
  const exportMenu = useRef();
  const exportButton = useRef();
  const selectNumOfItemsPerPage = useRef();

  const [valorTotal, setValorTotal] = useState(0);
  const [arrayContractLoad, setArrayContractLoad] = useState([]);

  const [originalData, setOriginalData] = useState(data);
  const [numOfItemsPerPage, setNumOfItemsPerPage] = useState(itemsPerPage);
  const [totalRows, setTotalRows] = useState(data.length);
  const [allHeaders, setAllHeaders] = useState([]);
  const [tableHeader, setTableHeader] = useState(header);
  const [tableData, setTableData] = useState([]);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [orderBy, setOrderBy] = useState({});
  // const [columnFilter, setColumnFilter] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState();
  const [exportVisibleColumns, setExportVisibleColumns] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [excelHeader, setExcelHeader] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    generateTableData();
    setExportVisibleColumns(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { }, [tableData, excelData]);

  useEffect(() => {
    gotoPage(activePage);
  }, [activePage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loaded.current) return false;
    gotoPage(activePage);
  }, [numOfItemsPerPage, orderBy, columnFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let filteredData = [...originalData];
    let filterObject = {};

    if (columnFilter.length > 0) {
      columnFilter.map((item, index) => {
        let [[key, value]] = Object.entries(item);
        if (filterObject[key] === undefined) {
          filterObject[key] = [];
        }
        filterObject[key].push(
          ...filteredData.filter(
            (row) => row[key].toLowerCase().indexOf(value.toLowerCase()) >= 0,
          ),
        );
        return true;
      });

      Object.entries(filterObject).map((item) => {
        let [key, arr] = item;
        let found = [];
        found = filteredData.filter((row) => {
          let arrItem = arr.filter(
            (filterItem) => row[idCol] === filterItem[idCol],
          );
          if (arrItem.length > 0) return true;
          return false;
        });
        filteredData = found;
        setTableHeader(Object.keys(filteredData[0]));
        return true;
      });
    }

    if (orderBy.mode === 'asc')
      filteredData.sort((a, b) => (a[orderBy.col] > b[orderBy.col] ? 1 : -1));
    if (orderBy.mode === 'desc')
      filteredData.sort((a, b) => (a[orderBy.col] < b[orderBy.col] ? 1 : -1));

    setExcelHeader(
      exportVisibleColumns ? [idCol, ...tableHeader] : [idCol, ...allHeaders],
    );
    setExcelData(filteredData);
  }, [exportVisibleColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateTableData = async () => {
    setLoading(true);
    // Comentei todos os getCache
    // let cacheData = await Utils.getCache(cache);
    
     // if (cacheData.columns.length > 0) {
     // } else {
     // }
    // setOrderBy(cacheData.orderBy || { col: idCol, mode: 'desc' });
    // setColumnFilter(cacheData.columnFilter || []);

    setOriginalData([...data]);
    setTotalRows(data.length);
    setPages(Math.ceil(data.length / numOfItemsPerPage));
    setActivePage(startPage || 1);
    if (data[0] != null) {
      setAllHeaders(Object.keys(data[0]).sort((a, b) => (a > b ? 1 : -1)));
    }
    setLoading(false);
  };

  const ExportExcel = (data, header) => {

    data = JSON.parse(JSON.stringify(data));
    data.forEach((dataAux, index) => {
      // console.log("excell data", dataAux);
      Object.keys(dataAux).forEach((key) => {
        if (dataAux[key]?.includes('$') && dataAux[key]?.length > 1) {
          data[index][key] = dataAux[key].replace('$', '');
          data[index][key] = dataAux[key].replace('.', '');
        }
      });
    });

    return (
      <ExcelFile
        element={<button className="excelDownloadButton">.XLSX</button>}
        filename="ShippingSchedule"
      >
        <ExcelSheet data={data} name="Shipping Schedule">
          {header.map((item) => {
            return <ExcelColumn label={item} value={item} />;
          })}
        </ExcelSheet>
      </ExcelFile>
    );
  };

  const handleSelectColumn = (row) => {
    let arrayIds = [];
    let misssingValue = 0;

    arrayIds.push({
      IdShipping: row.IdShipping,
      missingPrepayment: row['Missing Prepayment'] ?? 0
    });

    let ShippingId = row.IdShipping;

    arrayContractLoad.push({ ShippingId });
    misssingValue = arrayIds.missingPrepayment;

    setValorTotal(valorTotalFinal);
    setTotalReceived(misssingValue);
  }

  const ExportCsv = (data, header) => {
    data = JSON.parse(JSON.stringify(data));

    data.forEach((dataAux, index) => {
      Object.keys(dataAux).forEach((key) => {
        if (dataAux[key]?.includes('$') && dataAux[key]?.length > 1) {
          data[index][key] = dataAux[key].replace('$', '');
          data[index][key] = dataAux[key].replace('.', '');
        }
      });
    });

    return (
      <CsvDownloader
        filename="ShippingSchedule"
        separator=";"
        columns={header}
        datas={data}
        text="DOWNLOAD"
      >
        <span>
          <button
            className="excelDownloadButton"
            onClick={() => {
              ExportCsv(excelData, excelHeader);
            }}
          >
            .CSV
          </button>
        </span>
      </CsvDownloader>
    );
  };

  const renderTableRow = (row) => {
    return (
      <tr
        key={row[idCol]}
        onClick={() => {
          if (itemClick) itemClick(row, activePage);
          handleSelectColumn(row);
        }}
      >
        <td>
          {row[idCol]}
          <div className="tooltip">
            Click here to see your shipping's infos, products and documents
          </div>
        </td>

        {fixedCols.map((col) => {
          if (col === idCol) return false;
          if (row[col] != null) return <td>{row[col]}</td>;
          else return '';
        })}
        {tableHeader.map((col) => {
          if (col === idCol) return false;
          if (col === 'IdShipping') return false;
          if (fixedCols.indexOf(col) >= 0) return false;
          if (row[col] != null)
            return <td>{Utils.outputData(row[col], col)}</td>;
          else return '';
        })}
      </tr>
    );
  };

  const gotoPage = (pageNum) => {
    if (pageNum < 0) return;
    if (pageNum > pages) return;

    let filteredData = [...originalData];
    let filterObject = {};

    if (columnFilter.length > 0) {
      columnFilter.map((item, index) => {
        let [[key, value]] = Object.entries(item);
        if (filterObject[key] === undefined) {
          filterObject[key] = [];
        }
        filterObject[key].push(
          ...filteredData.filter(
            (row) => row[key].toLowerCase().indexOf(value.toLowerCase()) >= 0,
          ),
        );
        return true;
      });

      Object.entries(filterObject).map((item) => {
        let [key, arr] = item;
        let found = [];
        found = filteredData.filter((row) => {
          let arrItem = arr.filter(
            (filterItem) => row[idCol] === filterItem[idCol],
          );
          if (arrItem.length > 0) return true;
          return false;
        });
        filteredData = found;
        return true;
      });
    }

    if (orderBy.mode === 'asc')
      filteredData.sort((a, b) => (a[orderBy.col] > b[orderBy.col] ? 1 : -1));
    if (orderBy.mode === 'desc')
      filteredData.sort((a, b) => (a[orderBy.col] < b[orderBy.col] ? 1 : -1));

    if (Math.ceil(filteredData.length / numOfItemsPerPage) < pageNum) {
      setActivePage(Math.ceil(filteredData.length / numOfItemsPerPage));
      return;
    }

    setTotalRows(filteredData.length);
    setPages(Math.ceil(filteredData.length / numOfItemsPerPage) || 1);

    setExcelHeader(exportVisibleColumns ? tableHeader : allHeaders);
    setExcelData(filteredData);

    filteredData = filteredData.slice((pageNum - 1) * numOfItemsPerPage);

    if (filteredData.length > numOfItemsPerPage) {
      filteredData.length = numOfItemsPerPage;
    }
    setTableData(filteredData);
    loaded.current = true;
  };

  const renderPages = () => {
    let items = [];
    if (activePage > 6) {
      items.push(
        <div
          onClick={() => {
            setActivePage(1);
          }}
        >
          {1}
        </div>,
      );
      items.push(<span>...</span>);
    }
    for (let i = activePage - 5; i <= activePage + 5; i++) {
      if (i > 0 && i <= pages) {
        items.push(
          <div
            className={i === activePage ? 'active' : ''}
            onClick={() => {
              setActivePage(i);
            }}
          >
            {i}
          </div>,
        );
      }
    }
    if (activePage < pages - 6) {
      items.push(<span>...</span>);
      items.push(
        <div
          onClick={() => {
            setActivePage(pages);
          }}
        >
          {pages}
        </div>,
      );
    }
    return items;
  };

  const handleChangeColumn = async (evt, col) => {
    // Adiciona colunas na tabela ao clicar na lista
    let isSelected = evt.target.className;
    let headerCols = tableHeader;
    evt.target.classList.toggle('selected');
    if (!isSelected) {
      if (!orderedCols) {
        headerCols.push(col);
      } else {
        headerCols.splice(0, headerCols.length);
        document
          .querySelectorAll('.changeColumnMenu .selected')
          .forEach((item) => {
            headerCols.push(item.innerHTML);
          });
      }
    } else {
      let index = headerCols.indexOf(col);
      headerCols.splice(index, 1);
    }

    // salva as colunas que o usuario escolheu no cache
    Utils.setCache(cache, {
      columns: headerCols,
      columnFilter: columnFilter,
      orderBy: orderBy,
    });
    gotoPage(1);
  };

  const handleOrderBy = async (evt, col) => {
    let order = {};

    if (orderBy.col === col) {
      if (orderBy.mode === 'desc') {
        order = { col: '', mode: '' };
      } else {
        order = { col: col, mode: 'desc' };
      }
    } else {
      order = { col: col, mode: 'asc' };
    }
    await setOrderBy(order);
    // Comentei todos os setCache
    // Utils.setCache(cache, {
    //   columns: tableHeader,
    //   columnFilter: columnFilter,
    //   orderBy: order,
    // });
  };

  const handleSelectNumOfItemsPerPage = (evt) => {
    setNumOfItemsPerPage(evt.target.value);
    localStorage.setItem("itemPorPagina", evt.target.value);
  };

  const handleColumnFilter = async (evt, col, text, timeout = 500) => {
    let newObj = {};
    if (text.length >= 2) {
      newObj = [...columnFilter, { [col]: text }];
    } else {
      newObj = [...columnFilter];
    }
    await setColumnFilter(newObj);
    // await setTableFilters(newObj);
    // Comentei todos os setCache
    // console.log("cache", cache);
    // console.log("tableHeader", tableHeader);
    // console.log("newObj", newObj);
    // // console.log("orderBy", orderBy);
    // Utils.setCache(cache, {
    //   columns: tableHeader,
    //   columnFilter: newObj,
    //   orderBy: orderBy,
    // });
  };

  const toggleSearchCol = (evt) => {
    evt.target.parentElement.classList.toggle('active');
    if (evt.target.src.indexOf('search-white.svg') >= 0) {
      evt.target.src = '/images/icons/arrow-left-outline-icon.svg';
      evt.target.title = 'Cancel';
      evt.target.id = 'bk-input';
      var anchorElement = document.getElementById('bk-input');

      // var classe = document.getElementsByClassName('searchColContainer');
      // classe.style.display = 'flex';

      // var flexContainers = document.getElementsByClassName('searchColContainer');
      // for (var i = 0; i < flexContainers.length; i++) {
      //   flexContainers[i].style.display = 'flex';
      // }

      // Focus the anchor element
      if (anchorElement) {
        anchorElement.focus();
      }

      // console.log("evt.target.parentElement", anchorElement);
      // evt.target.parentElement.childNodes[1].focus();
      evt.target.parentElement.scrollTo(0, 0);
    } else {
      evt.target.src = '/images/icons/search-white.svg';
      evt.target.title = 'Edit Filter';
    }
  };

  const changePosition = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  };

  const handleMoveColumn = (index, forward) => {
    let headerCols = tableHeader;

    if (forward) {
      if (index + 1 < headerCols.length) setTableHeader(changePosition(headerCols, index, index + 1));
    }
    else {
      if (index - 1 >= 0) setTableHeader(changePosition(headerCols, index, index - 1));
    }

    Utils.setCache(cache, {
      columns: headerCols,
      columnFilter: columnFilter,
      orderBy: orderBy,
    });

    gotoPage(activePage);
  }

  // Render Stuff

  return (
    <div ref={tableContainer} className="tableContainer">
      <div className="tableOptions">
        <div className="numOfItems">
          <div
            className="back"
            onClick={() => {
              if (activePage === 1) return;
              setActivePage(activePage - 1);
            }}
          >
            <p className="arrow noselect">◂</p>
          </div>
          <div className="itemsText noselect">
            row{' '}
            <span className="red">
              {activePage * numOfItemsPerPage - numOfItemsPerPage + 1 < 0
                ? 0
                : activePage * numOfItemsPerPage - numOfItemsPerPage + 1}
            </span>{' '}
            to{' '}
            <span className="red">
              {activePage * numOfItemsPerPage > totalRows
                ? totalRows
                : activePage * numOfItemsPerPage || 0}
            </span>{' '}
            from <span className="red">{totalRows}</span>
          </div>
          <div
            className="forward"
            onClick={() => {
              if (activePage === pages) return;
              setActivePage(activePage + 1);
            }}
          >
            <p className="arrow noselect">▸</p>
          </div>
          <div className="numOfItemsPerPage noselect">
            <span>Show</span>
            <select
              ref={selectNumOfItemsPerPage}
              value={numOfItemsPerPage}
              onChange={handleSelectNumOfItemsPerPage}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>Items per Page</span>
          </div>
        </div>
        {!noExport && (
          <div className="export noselect" ref={exportButton}>
            <h1>Export</h1>
            <div ref={exportMenu} className="exportMenu">
              <h2>Choose file type</h2>
              <div className="checkColumns">
                <input
                  value={exportVisibleColumns}
                  type="checkbox"
                  id="checkCol"
                  onClick={(e) => {
                    setExportVisibleColumns(e.target.checked);
                  }}
                />
                <label htmlFor="checkCol">Export only visible columns</label>
              </div>
              {excelData.length > 0 && ExportExcel(excelData, excelHeader)}
              {excelData.length > 0 && ExportCsv(excelData, excelHeader)}
            </div>
          </div>
        )}
        {!noOrderBy && (
          <div className="orderBy noselect" ref={orderByButton}>
            <h1>Order By</h1>
            <div ref={orderByMenu} className="orderByMenu">
              <ul>
                {orderBy.col === idCol ? (
                  <li
                    key={'id'}
                    className={orderBy.mode}
                    onClick={(evt) => {
                      handleOrderBy(evt, idCol);
                    }}
                  >
                    {idCol}
                  </li>
                ) : (
                  <li
                    key={'id'}
                    onClick={(evt) => {
                      handleOrderBy(evt, idCol);
                    }}
                  >
                    {idCol}
                  </li>
                )}
                {tableHeader.map((item, index) => {
                  if (orderBy.col === item) {
                    return (
                      <li
                        key={index}
                        className={orderBy.mode}
                        onClick={(evt) => {
                          handleOrderBy(evt, item);
                        }}
                      >
                        {item}
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={index}
                        onClick={(evt) => {
                          handleOrderBy(evt, item);
                        }}
                      >
                        {item}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        )}
        {!noChangeColumns && (
          <div className="changeColumns noselect" ref={changeColumnButton}>
            <h1>Change Columns</h1>
            <div ref={changeColumnMenu} className="changeColumnMenu">
              <ul>
                {allHeaders.map((item, index) => {
                  if (item === idCol) return false;
                  if (fixedCols.indexOf(item) >= 0) return false;
                  if (hiddenCols.indexOf(item) >= 0) return false;
                  return (
                    <li
                      key={index}
                      className={
                        tableHeader.indexOf(item) >= 0 ? 'selected' : ''
                      }
                      onClick={(evt) => {
                        handleChangeColumn(evt, item);
                      }}
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Loader color="#E30613" />
      ) : (
        <div className="customTable">
          <table cellSpacing="0">
            <thead>
              <tr>
                <th className={orderBy.col === idCol ? orderBy.mode : ''}>
                  <div className="headerText">
                    <p>{idCol}</p>
                  </div>
                    {/* <img
                      style={{"width": "20px", "height": "20px", "position": "absolute", "top": "24px", "right": "0px"}}
                      onClick={toggleSearchCol}
                      src="/images/icons/search-white.svg"
                      alt=""
                      title="Edit Filter"
                    />
                  <div className={`searchColContainer`}>
                    <input
                      className="searchCol"
                      type="text"
                      placeholder="Type and press enter"
                      onKeyUp={(e) => {
                        if (e.which === 13) {
                          e.target.parentElement.childNodes[0].click();
                          handleColumnFilter(e, idCol, e.target.value);
                        } else if (e.which === 27) {
                          e.target.value = '';
                          handleColumnFilter(e, idCol, '', 0);
                          e.target.parentElement.childNodes[0].click();
                        } else {
                        }
                      }}
                    />
                  </div> */}
                </th>
                {fixedCols.map((item, index) => {
                  console.log("fixedCols", item)
                  if (item === idCol) return false;
                  if (allHeaders.indexOf(item) >= 0) {
                    return (
                      <th className={orderBy.col === item ? orderBy.mode : ''}>
                        <div className="headerText">
                          <p>{item}</p>
                        </div>
                        {/* <img
                          onClick={toggleSearchCol}
                          src="/images/icons/search-white.svg"
                          alt=""
                          title="Edit Filter"
                        />
                        <div className={`searchColContainer`}>
                          <input
                            className="searchCol"
                            type="text"
                            placeholder="Type and press enter..."
                            onKeyUp={(e) => {
                              if (e.which === 13) {
                                e.target.parentElement.childNodes[0].click();
                                handleColumnFilter(e, item, e.target.value);
                              } else if (e.which === 27) {
                                e.target.value = '';
                                handleColumnFilter(e, item, '', 0);
                                e.target.parentElement.childNodes[0].click();
                              } else {
                              }
                            }}
                          />
                        </div> */}
                      </th>
                    );
                  } else return '';
                })}
                {tableHeader.map((item, index) => {
                  if (item === idCol) return false;
                  if (item === 'IdShipping') return false;
                  if (fixedCols.indexOf(item) >= 0) return false;
                  if (allHeaders.indexOf(item) >= 0) {
                    return (
                      <th className={orderBy.col === item ? orderBy.mode : ''}>
                        <div className="headerText">
                          <p>{item}</p>
                          <div className='changePositionDiv'>
                            <button onClick={() => handleMoveColumn(index, false)}>◂</button>
                            <button onClick={() => handleMoveColumn(index, true)}>▸</button>
                          </div>
                        </div>
                        {/* <img
                          style={{"width": "20px", "height": "20px", "position": "absolute", "top": "24px", "right": "0px"}}
                          onClick={toggleSearchCol}
                          src="/images/icons/search-white.svg"
                          alt=""
                          title="Edit Filter"
                        />
                        <div className={`searchColContainer`}>
                          <input
                            className="searchCol"
                            type="text"
                            placeholder="Type and press enter..."
                            onKeyUp={(e) => {
                              if (e.which === 13) {
                                e.target.parentElement.childNodes[0].click();
                                handleColumnFilter(e, item, e.target.value);
                              } else if (e.which === 27) {
                                e.target.value = '';
                                handleColumnFilter(e, item, '', 0);
                                e.target.parentElement.childNodes[0].click();
                              } else {
                              }
                            }}
                          />
                        </div> */}
                      </th>
                    );
                  } else return '';
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, index) => {
                  return renderTableRow(row, index);
                })
              ) : (
                <tr key="nodata">
                  <td colSpan={tableHeader.length + 1}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <div className="pages">{renderPages()}</div>
    </div>
  );
};

export default Table;
