import React, { useState, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Loading, Header, Button } from '../../Components/Components';
import { UserContext } from '../../Components/Contexts';

import Shipping from '../Shipping/Shipping';

import './Home.scss';

const Home = () => {
  const history = useHistory();
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const handleAccessButtonClick = () => {
    window.location.href = '#links';
  };
  const handleShippingButton = () => {
    history.push('/shipping/schedule-documents');
  };

  return (
    <div className="main">
      <Loading visible={loading} />
      <Header user={user} setLoading={setLoading} />
      <Switch>
        <Route path="/shipping">
          <Shipping setLoading={setLoading} />
        </Route>
        <Route path="/">
          <div className="contentHome pageContent">
            <div className="top">
              <div className="overlay">
                <img
                  className="logo"
                  src="/images/logos/Logo-flag.png"
                  alt=""
                />
                <p>
                  As a global and diversified protein company, attending
                  customers and consumers around the world, we would like to
                  welcome you to JBS client portal. Here you will have access to
                  a set of tools and solutions that will enable you to manage
                  all logistics information. All information you wanted the most
                  in one place.
                </p>
                <div className="accessButton">
                  <Button
                    type="red"
                    caption="Access"
                    onClick={handleAccessButtonClick}
                    shape="square"
                  />
                </div>
              </div>
            </div>
            <div className="links">
              <a id="links"> </a>
              <div className="linksTop">
                <h1>Shipping</h1>
                <p>
                  As a global and diversified protein company, attending
                  customers and consumers around the world, we would like to
                  welcome you to JBS client portal. Here you will have access to
                  a set of tools and solutions that will enable you to manage
                  all logistics information. All information you wanted the most
                  in one place.
                </p>
              </div>
              <div className="linksContent">
                <div className="linksSchedule">
                  <h1>Schedule</h1>
                  <p>
                    Get Shipping Schedule information with real-time updates.
                    Track all supply chain data and have a broader picture of
                    your orders.
                  </p>
                </div>
                <div className="linksDocuments">
                  <h1>Documents</h1>
                  <p>
                    Follow up your documents using our services is the easiest
                    and fastest way to manage and access all information.
                  </p>
                </div>
                <div className="linksClaim">
                  <h1>Financial</h1>
                  <p>
                    We provide strong financial tool management solution for
                    your needs. View all your pending financial matters with a
                    few clicks using an intuitive system.
                  </p>
                </div>
              </div>
              <div className="seeMoreContainer">
                <Button
                  caption="See More"
                  onClick={handleShippingButton}
                  shape="square"
                  type="red"
                />
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Home;
