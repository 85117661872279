import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import FirstLogin from './pages/FirstLogin/FirstLogin';
import { UserContext } from './Components/Contexts';

// Stylesheet
import './App.scss';
import Utils from './Components/utils';

function App() {
  const [isLogedIn, setLogedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const [account, setAccount] = useState(null);
  const [regional, setRegional] = useState(null);
  const [area, setArea] = useState(null);

  let user = null;

  useEffect(() => {
    document.title = 'Friboi';
    getLogedInUser();
  }, []);

  useEffect(() => {}, [isLogedIn]);

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () =>{
    const accountInfo = JSON.parse(localStorage.getItem("account"));
    // if(accountInfo == null){
    //   let clientData = await Utils.getClientsShippingData();
    //   localStorage.setItem("account", clientData[0].CodERPLogistico);
    //   setAccount(clientData[0].CodERPLogistico);
    // }else{
    //   setAccount(accountInfo);
    // }
    if (accountInfo) setAccount(accountInfo);
  }

  const getLogedInUser = async () => {
    const storage = await localStorage.getItem('logedInUser');

    if (storage) {
      user = await Utils.getUserData();
      setLogedIn(user);
    } else {
      setLoading(false);
    }
  };

  if (!isLogedIn) {
    return (
      <div className="App">
        {loading ? (
          <div className="loading"></div>
        ) : (
          <Router>
            <Switch>
              <Route path="/changepassword" component={ChangePassword} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route path="/firstlogin" component={FirstLogin} />
              <Route path="/">
                <Login />
              </Route>
            </Switch>
          </Router>
        )}
      </div>
    );
  } else {
    return (
      <div className="App">
        <UserContext.Provider value={{isLogedIn, account, setAccount}}>
          <Router>
            <Home />
          </Router>
        </UserContext.Provider>
      </div>
    );
  }
}

export default App;
