import { useState, useRef, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import './Header.scss';
import { TextInput, Button, Utils } from '../Components';
import { UserContext } from '../Contexts';

import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';


export const Header = ({ setLoading }) => {
  const { isLogedIn, account, setAccount } = useContext(UserContext);

  const dropdownUserMenu = useRef();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [nameAccount, setNameAccount] = useState([]);

  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    ListTheAccounts();
  }, [])

  const handleLogout = () => {
    Utils.Confirm({
      title: 'Logout',
      text: 'You really wish to logout?',
      onYes: () => {
        localStorage.removeItem('logedInUser');
        localStorage.removeItem('account');
        localStorage.removeItem('nameClient');
        window.location.href = '/portal';
      },
    });
  };

  const handleChangePassword = () => {
    // dropdownUserMenu.current.classList.toggle('reset');
    const { email } = JSON.parse(localStorage.getItem('logedInUser'));
    localStorage.removeItem('logedInUser');
    localStorage.removeItem('account');
    localStorage.removeItem('nameClient');
    localStorage.setItem('email', email);
    window.location.href = '/changepassword';
  };

  const handleResetConfirm = async () => {
    setLoading(true);
    const userData = localStorage.getItem('logedInUser');
    const { email } = JSON.parse(userData);

    if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
      Utils.Alert({
        title: 'Warning',
        text: 'All fields are required!',
      });
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      Utils.Alert({
        title: 'Warning',
        text: "New password and confirmation don't match.",
      });
      setLoading(false);
      return;
    }

    const validation = await Utils.ValidateUser(email, oldPassword);


    if (validation.error) {
      Utils.Alert({
        title: 'Warning',
        text: 'The old password you entered is invalid',
      });
      setLoading(false);
      return;
    }

    const newPass = await Utils.GenerateHash(newPassword);

    const newPassData = {
      email: email,
      hash: newPass.hash,
    };


    // const result = await Utils.oic.post(
    //   '${process.env.REACT_APP_URL}/portal/update_hash',
    //   newPassData,
    // );

    let postData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: validation.token,
      },
      body: JSON.stringify(newPassData),
    };

    const result = await fetch(
      `${process.env.REACT_APP_URL}/portal/update_hash`,
      postData,
    );

    // await Utils.oic.post(
    //   '${process.env.REACT_APP_URL}/portal/update_hash',
    //   newPassData,
    // );

    if (result.status === 200) {
      Utils.Alert({
        title: 'Success',
        text: 'Password successfuly updated, please sign in again.',
        callback: () => {
          localStorage.removeItem('logedInUser');
          window.location.href = '/portal';
        },
      });
    } else {
      Utils.Alert({
        title: `Error: ${result.status}`,
        text: "Password couldn't be updated, please try again later",
      });
      setLoading(false);
    }
  };

  const handleResetCancel = () => {
    dropdownUserMenu.current.classList.toggle('reset');
  };

  const ListTheAccounts = async () => {
    let shippingAccounts = await Utils.getAccountList();

    console.log(...shippingAccounts);

    shippingAccounts = shippingAccounts.filter((item) => {
      return item.Mensagem != 'Not Authorized'
    });

    setAccountList([...shippingAccounts]);

    if (!localStorage.getItem("nameClient")) {
      setNameAccount(shippingAccounts[0].nameConta);
      setAccount(shippingAccounts[0]);
      localStorage.setItem('account', JSON.stringify(shippingAccounts[0]));
      localStorage.setItem('nameClient', shippingAccounts[0].nameConta);
    } else {
      // if (!localStorage.getItem('account')) {
      //   const acc = shippingAccounts.filter((acc) => acc.nameConta === localStorage.getItem("nameClient"))[0];
      //   setAccount(acc);
      //   localStorage.setItem('account', JSON.stringify(acc));
      // }

      const acc = shippingAccounts.filter((acc) => acc.nameConta === localStorage.getItem("nameClient"))[0];
      setAccount(acc);
      localStorage.setItem('account', JSON.stringify(acc));
      setNameAccount(localStorage.getItem("nameClient"));
    }
  }

  const changeLogisticoShipping = (item) => {
    setAccount(item);
    setNameAccount(item.nameConta);
    // localStorage.setItem("account", item.erpConta);
    localStorage.setItem("account", JSON.stringify(item));
    localStorage.setItem("nameClient", item.nameConta);
  }

  const changeBuyerShipping = (code, nameClient) => {
    setAccount(code);
    setNameAccount(nameClient);
    localStorage.setItem("account", code);
    localStorage.setItem("nameClient", nameClient);
  }

  return (
    <nav>
      <div className="logo">
        <img src="/images/logos/header_logo.png" alt="" />
      </div>

      <div>
        <div className="accountSelect">
          <Dropdown text={nameAccount}>
            <Dropdown.Menu scrolling>
              {accountList.map((item, index) => (
                <div key={index}>
                  <div className="terceiroConta">
                    {item.nameConta && (
                      <Button key={item.erpConta}
                        style={{
                          backgroundColor: '#fff',
                          boxShadow: 'none',
                          fontFamily: 'Roboto Condensed',
                          color: '#102161',
                          fontSize: '14px',
                        }}
                        onClick={() => changeLogisticoShipping(item)}
                      >
                        <Dropdown.Item text={item.nameConta} />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="rightMenu">
        <ul className="menu">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/shipping/schedule-documents">Shipping</Link>
          </li>
          <li className="userMenu">
            {isLogedIn.name}
            <div className="dropdownUserMenu" ref={dropdownUserMenu}>
              <ul className="hidden">
                <li className="logout" onClick={handleChangePassword}>
                  Change Password
                </li>
                <li className="logout" onClick={handleLogout}>
                  Logout
                </li>
              </ul>
              <div className="resetPassword">
                <h2>Reset Password:</h2>
                <form className="passwordResetForm">
                  <TextInput
                    value={oldPassword}
                    type="password"
                    caption="Old Password"
                    onChange={setOldPassword}
                  />
                  <TextInput
                    value={newPassword}
                    type="password"
                    caption="New Password"
                    onChange={setNewPassword}
                  />
                  <TextInput
                    value={confirmPassword}
                    type="password"
                    caption="Confirm Password"
                    onChange={setConfirmPassword}
                  />
                  <Button
                    type="blue"
                    caption="Confirm"
                    onClick={handleResetConfirm}
                  />
                  <Button
                    type="red"
                    caption="Cancel"
                    onClick={handleResetCancel}
                  />
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;